const appConfig = {
    // apiPrefix: 'http://localhost:3003/api', // local
    // apiPrefix: 'http://ec2-35-86-235-197.us-west-2.compute.amazonaws.com:3003/api', // dev
    apiPrefix: 'https://s.keepmycodes.com/api', // prod
    enableMock: false,
    authenticatedEntryPath: '/code/list',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/account/kyc-form',
    // uncomment for local dev against fake apis
    // apiPrefix: '/api',
    // enableMock: true
};

export default appConfig;